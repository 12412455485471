import React from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';

import { SocialGroupsApp } from 'common/components/SocialGroupsApp';

import { COMPONENT } from 'settings/consts';

import type { IVMProps } from '../../../vm/types';

import { Widget } from './Widget';

COMPONENT.name = 'FeedWidget';

function FeedWidget(props: WidgetProps<IVMProps>) {
  return (
    <SocialGroupsApp {...props}>
      <Widget />
    </SocialGroupsApp>
  );
}

FeedWidget.displayName = 'FeedWidget';

export default FeedWidget;
